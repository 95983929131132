<template>
  <div>
    <div v-if="!result"></div>
    <a-result
      v-if="result == '1'"
      status="success"
      title="授权激活成功"
      sub-title="请返回平台并且刷新页面"
    >
    </a-result>
    <a-result
      v-if="result == '2'"
      status="warning"
      title="授权激活失败"
      :sub-title="errMsg"
    >
    </a-result>
  </div>
</template>

<script>
import {
  getAction,
  deleteAction,
  putAction,
  postAction,
  httpAction,
} from "@/api/manage";
import axios from "axios";
export default {
  name: "App",
  data() {
    return {
      url: {
        auth: "/userQy/auth",
      },
      result: "",
      errMsg: "",
      msg: "",
      test: "",
      fcn: "",
      res: "",
      apiurl: "",
    };
  },
  components: {},
  created() {
    // this.msg = "进入created了";
  },
  mounted() {
    // 获取 转码   参数地址
    let test = location.href;
    this.test = location.href;
    // 截取获取 code   state 参数
    // debugger;
    let str = test.substring(test.indexOf("=") + 1, test.indexOf("&"));
    let username = test.match(/state=(\S*)/)[1];
    // 授权接口
    this.reqAuth(str, username);
  },
  methods: {
    handleBtn() {},
    getUrlKey(name) {
      return (
        decodeURIComponent(
          (new RegExp("[?|&]" + name + "=" + "([^&;]+?)(&|#|;|$)").exec(
            location.href
          ) || [, ""])[1].replace(/\+/g, "%20")
        ) || null
      );
    },
    getUserName(str, firstStr, secondStr) {
      var subFirstStr = str.substring(
        str.indexOf(firstStr) + firstStr.length,
        str.length
      );
      var subSecondStr = subFirstStr.substring(
        0,
        subFirstStr.indexOf(secondStr)
      );
      return subSecondStr;
    },
    reqAuth(key, username) {
      let that = this;
      let params = {
        code: key,
        userName: username,
      };
      this.apiurl = window._CONFIG["domianURL"];
      this.fcn = "授权函数";
      getAction(this.url.auth, params).then((res) => {
        that.res = JSON.stringify(res);
        if (res.code == 200) {
          that.result = "1";
        } else {
          that.result = "2";
          that.errMsg = res.message;
        }
      });
    },
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
