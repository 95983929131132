
import axios from 'axios'




/**
 * 【指定 axios的 baseURL】
 * 如果手工指定 baseURL: '/jeecg-boot'
 * 则映射后端域名，通过 vue.config.js
 * @type {*|string}
 */

let apiBaseUrl = window._CONFIG['domianURL'] || "/jeecg-boot";

//console.log("apiBaseUrl= ",apiBaseUrl)
// 创建 axios 实例
const service = axios.create({
  //baseURL: '/jeecg-boot',
  baseURL: apiBaseUrl, // api base_url
  timeout: 9000 // 请求超时时间
})
service.interceptors.request.use(config => {
  return config
})
service.interceptors.response.use((response) => {

  return response.data
})

export {
  service as axios
}